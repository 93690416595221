<script>
export default {
  components: {}
};
</script>
<style scoped>
.display-mobile{
  display: none;
}
.text-resp{
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  font-weight: 400;
}
@media (max-width: 402px) {
  .display-mobile{
    display: block;
  }
  .display-webs{
    display: none;
  }
}
</style>

<template>
  <div>
    <b-navbar variant="faded" id="navbarauth" type="light">
      <b-navbar-brand href="https://jaybod.com">
          <div class="bg-white d-flex justify-content-center my-4 py-4">
                <img src="@/assets/images/Logo.svg"  alt class="cursor-pointer px-2 img-fluid" />
          </div>
      </b-navbar-brand>
    </b-navbar>
    <div class="account-pages">
      <div class="mt-4 mx-4 px-4">
        <slot class="mx-4"/>
      </div>
    </div>
    <footer class="bottom-0 pt-4 mt-4 mx-4">
      <hr class="mt-4">
        <div class="display-webs">
          <div id="footer" class="d-flex justify-content-between align-item-start">
            <p class="text">
              © {{new Date().getFullYear()}} desktopIP Limited. All rights reserved.
            </p>
            <div class="d-flex justify-content-between align-item-start">
              <a href="https://contacts.jaybod.com/helpcenter/" target="_blank" class="text text-blue bold-600 px-2 mx-2">Help</a>
              <a href="https://jaybod.com/terms-service" target="_blank" class="text text-blue bold-600 px-2 mx-2">Terms</a>
              <a href="https://jaybod.com/terms-service" target="_blank" class="text text-blue bold-600 px-2 mx-2">Privacy</a>
            </div>
          </div>
        </div>
        <div class="display-mobile">
          <div id="footer" class="d-flex justify-content-between align-item-start">
            <p class="text-resp">
              © {{new Date().getFullYear()}} desktopIP Limited. All rights reserved.
            </p>
            <div class="d-flex justify-content-between align-item-start">
              <a href="https://contacts.jaybod.com/helpcenter/" target="_blank" class="text-resp text-blue bold-600 px-2 mx-2">Help</a>
              <a href="https://jaybod.com/terms-service" target="_blank" class="text-resp text-blue bold-600 px-2 mx-2">Terms</a>
              <a href="https://jaybod.com/terms-service" target="_blank" class="text-resp text-blue bold-600 px-2 mx-2">Privacy</a>
            </div>
          </div>
        </div>
    </footer>
  </div>
</template>
