<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import Navbar from "@/components/nav-login-registerV3.vue";

/**
 * Login component
 */
export default {
  page: {
    title: "Confirm Your Email",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    // Layout,
    Navbar
    },
  data() {
    return {
      email: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      invalidEmail : "Your account or Email is incorrect.",
      stateEmail : true,
      loading : false,
      landing : process.env.VUE_APP_LANDING,
      durations: 0
    };
  },
  mounted() {
    // this.$ga.page('/verify')
    this.$ga.event(process.env.VUE_APP_GA_PAGE_VERIFY, 'Load Halaman Verifikasi', 'signup')
  },
  watch: {
    email() {
        if(this.email == null || this.email == ''){
            this.stateEmail = true
            this.invalidEmail = ''
        }
    }
  },
  computed: {
    timeDisplay() {
      const seconds = this.durations % 60
      return seconds
    }
  },
  methods: {
    ...authMethods,
    goToResend(){
      let emailResend = localStorage.getItem("newUserEmail")
      this.$store.dispatch('auth/resendCode', {email: emailResend})
      .then(()=>{
        this.$toasted.show('New verification code has been send to your email.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 5000
        })
        this.durations = 30
        var interval = setInterval(() => {
          this.durations -= 1
          if(this.durations <= 0){
            clearInterval(interval)
          }
        }, 1000)
        this.timeDisplay
      })
      .catch((err)=>{
        this.$toasted.show('Failed to resend verification code send to your email.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 5000
        })
      })
    },
    landingPage(){
      window.open(this.landing,"_self")
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.$ga.event(process.env.VUE_APP_GA_BUTTON_VERIFY, 'Click Button Verify', 'signup', 1)
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.loading = true;
      return (
        this.verify({
          vcode: this.email,
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.stateEmail = true;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
            this.$toasted.show('Email verified.',{ 
              theme: "toasted-primary", 
              position: "bottom-center", 
              duration : 5000
            })
          })
          .catch(error => {
            this.tryingToLogIn = false;
            // this.authError = error ? error : "";
            this.invalidEmail = error ? error.data.display_message : "";
            this.stateEmail = false;
            this.loading = false;
            // this.isAuthError = true;
          })
      );
    }
  }
};
</script>

<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
        <div class="center-content-v3">
            <div class="container-permission-auth">
                <span class="title-permission-auth">Verify your email</span>
                <span class="my-3 sub-permission-auth">Enter the code received via email to confirm.</span>
                <form action="" @submit.prevent="tryToLogIn">
                    <input 
                        type="text"
                        placeholder="Your verification code"
                        :class="stateEmail != true ? 'form-input-auth-code red-border-auth' : 'form-input-auth-code'"
                        v-model="email"
                    >
                </form>
                <span v-if="stateEmail != true" class="invalid-code-auth">{{invalidEmail}}</span>
                <button @click="tryToLogIn()" :disabled="email == null || email == ''" class="my-3 btn-submit-auth-code"><i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>Verify</button>
                <div v-if="timeDisplay > 0" class="d-flex justify-content-center align-items-center">
                    <span class="sub-permission-auth p-2">Resend verification email in {{ timeDisplay }}s</span>
                </div>
                <div v-else class="link-backup-page">
                    <a @click="goToResend()" class="main-color-api cursor-pointer">Resend verification email</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
.form-input-auth-code[type="number"]::-webkit-outer-spin-button,
.form-input-auth-code[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-input-auth-code[type="number"] {
    -moz-appearance: textfield;
}
.form-input-auth-code{
    border: 1px solid #E9EAEA;
    outline: none;
    border-radius: 4px;
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
    padding: 16px;
    width: 100%;
}
.form-input-auth-code::placeholder{
    color: #919394;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}

.container-permission-auth{
    border-radius: 8px;
    width: 426px;
    background-color: #FFFFFF;
    height: fit-content;
    padding: 32px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 16px;
}
.title-permission-auth{
    color: #262A2C;
    line-height: 30px;
    font-size: 24px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.04em;
}
.sub-permission-auth{
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
.link-backup-page{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
}
.red-border-auth{
    border: 1px solid #D42020;
}
.invalid-code-auth{
    color: #D42020;
}
</style>
